/* You can add global styles to this file, and also import other style files */

@import "~@ng-select/ng-select/themes/default.theme.css";

@import "~ng-pick-datetime/assets/style/picker.min.css";
html, body { height: 100%; scroll-behavior: smooth; overflow: hidden; }
// body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
body { margin: 0; font-family: HKGrotesk, "Helvetica Neue", sans-serif; }

.clickable {
    cursor: pointer;
}


.top-space{
  padding-top: 0px;
}

.error{
  color: #FE5C5E;
}
/*Toaster start*/
#toast-container{
  width: 100%;
  bottom: 0px;
  left: 0;
  right: 0;
  text-align: center;
}
#toast-container>.toast-success {
  background: #14DEBF;
  border-radius: 50px;
}
#toast-container>.toast-error{
  background: #FE5C5E;
  border-radius: 50px;
}
#toast-container>.ngx-toastr{
  border-radius: 0px;
  width: 100%;
  box-shadow: none;
  padding-top: 10px;
  padding-bottom: 10px;
  margin: 0;
}
/*Toaster end*/

/* Button start */
.stnd-bg-btn{
  font-family: HKGrotesk;
  font-size: 14px;
  font-weight: bold;
  color: #ffffff;
  background: #23bea7;
  border: 0;
  padding: 10px 20px;
  cursor: pointer;
}
.stnd-bg-btn:focus{
  outline: none;
}
/* Button end */

/* Slim scrollbar start */
.slim-scrollbar::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
.slim-scrollbar::-webkit-scrollbar-track {
  background: #555;
}

/* Handle */
.slim-scrollbar::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.slim-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #f1f1f1;
}
/* Slim scrollbar end */

/* Welcome text start*/
.welcome-section{
  color: #909090;
  font-size: 18px;
  padding: 30px 40px;
  border-bottom: 1px solid #36393d;

  .date-picker-input{
    background: transparent;
    border: 0;
    // color: #23bea7;
    color: #909090;
    text-decoration: underline;
    // font-size: 16px;
    font-size: 12px;
    cursor: pointer;
    font-weight: 600;
  }
  .anchor-btn{
    color: #23bea7;
    font-size: 14px;
    text-decoration: underline;
    font-weight: 600;
  }
  .right-part{
    text-align: right;
  }
}
/* Welcome text end*/
